import { apiHeaders } from '../../utils/utils'
import axiosWrapper from '../helpers/api'
import { ERequestUrl, EMethods } from '../helpers/requests'
import { LOCAL_STORAGE_KEYS } from '@/utils/constants'

export const getInProgressContractCSUB = (state) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.contractCSUB,
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE),
      true
    ),
    isV2: true,
    uid: `?state=${state}`,
  })

export const getInProgressContractSUB = (state) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.contractSUB,
    headers: apiHeaders(
      localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE),
      true
    ),
    isV2: true,
    uid: `?state=${state}`,
  })
